import { inject } from 'vue';
import { subscribeKey, getItemKey, getServerItemKey } from '@drapejs/core';

export default function () {

  return {
    subscribe: inject(subscribeKey, () => { return null as any }),
    getItem: inject(getItemKey, () => { return null as any }),
    getServerItem: inject(getServerItemKey, () => { return null as any }),
  }

}